import { render, staticRenderFns } from "./unitCard.vue?vue&type=template&id=4916ff12&scoped=true&lang=html&"
import script from "./unitCard.vue?vue&type=script&lang=js&"
export * from "./unitCard.vue?vue&type=script&lang=js&"
import style0 from "./unitCard.vue?vue&type=style&index=0&id=4916ff12&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4916ff12",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCol,VIcon,VSpacer,VSwitch})
